@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    @apply overflow-hidden;
  }

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.topBarButton {
    color: #cccccc;
}

.topBarButton:hover {
    color: #a1a1aa;
}

.timerButton {
    color: #838383;

}

.timerButton:hover {
    color: #a1a1aa;
}


.moonButton {
    color: #475569;
}

.moonButton:hover {
    color: #f7e877;
}

.fireButton {
    color: #475569;
}

.fireButton:hover {
    color: #ea580c;
}


.windButton:hover {
    color: #1db957;
}

.rainButton {
    color: #475569;
}

.rainButton:hover {
    color: #60a5fa;
}

.closeButton {
    color: #64748b;
}

.closeButton:hover {
    color: #94a3b8;
}
